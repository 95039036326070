import '@aws-amplify/ui-react/styles.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src="/bow-realty-logo.png" className="App-logo" alt="logo" />
      <div className="App-body">
        <a 
        className="App-link"
        href="https://maps.google.com/?q=1727 26th street sw, Calgary, AB, Canada, Alberta"
        target="_blank">
          <p> 
            1727 26th Street SW, Calgary, AB
          </p>
          <p>
            T3C1K7
          </p>  
        </a> 
        <p> 
          <a className="App-link" href="mailto:info@bowrealty.ca">info@bowrealty.ca</a>
        </p>
        <p> 
          <a 
          className="App-link"
          href="tel:+1-403-245-5858">
            (403) 245-5858
          </a>
        </p>
          <div id="social links" class="social_div">
            <a 
            className="App-link"
            href="https://www.instagram.com/bowrealty/"
            target="_blank">
            <img src="/instagram icon.png" className="instagram-logo"/>
          </a>
          <a 
            className="App-link"
            href="https://www.facebook.com/bowrealty/"
            target="_blank">
            <img src="/logo-facebook.png" className="facebook-logo"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
